import axiosServices from './request';

const UserApi = axiosServices('/party');

export default {
  // 公众号授权回调
  wxAuthorizeRedirect: UserApi('get', 'component/wxAuthorizeRedirect'),

  // 公众号授权
  getComponentAuthPageUrl: UserApi('get', 'component/getComponentAuthPageUrl'),

  // 公众号列表
  getAccountSettingList: UserApi('get', 'accountSetting/getAccountSettingList'),

  // 判断token 是否过期
  checkToken: UserApi('get', 'party/checkToken'),

  // 通过域名获取产品信息
  getIndustryCase: UserApi('get', 'userConfig/getIndustryCaseByCode'),

  // 登录
  login: UserApi('post', 'party/login'),

  // 重置密码
  resetPassword: UserApi('post', 'party/resetPassword'),

  // 机构注册
  mechanismRegister: UserApi('post', 'party/register'),

  // 查机构列表
  getPartyNameList: UserApi('get', 'party/getPartyNameList'),

  // 获取首页实时数据
  getHomePageData: UserApi('get', 'party/getBaseData4HomePage'),

  // 首页读者列表
  getHomePageUserList: UserApi('get', 'party/getUserList4HomePage'),

  // 首页数据每天列表（默认30天），浏览量、扫码量、收藏量，按时间筛选
  getHomePageDayData: UserApi('get', 'party/getBaseDateMonth4HomePage'),

  // 首页获取今日。昨日，总销售额
  getSaleVolume: UserApi('get', 'order/getSaleVolume'),

  // 首页读者数据统计
  readerStats: UserApi('get', 'data/readerStats'),

  // 首页最近七天热门展品
  getRecent7DayHotList: UserApi('post', 'data/listRecent7DayHotStatistics'),

  // 首页最近七天热门应用
  getRecent7DayHotAppList: UserApi('get', 'app/listRecent7DayHotStatistics'),

  // 首页获取服务数量
  getServeCount: UserApi('get', 'data/getServeCount'),

  // 根据token查信息
  getInfo: UserApi('get', 'party/getInfo'),

  //刷新token
  logout: UserApi('post', 'party/logout'),

  // 获取用户信息
  getUserInfo: UserApi('get', 'member/getMemberBaseInfo'),

  // 成员注册
  memberRegister: UserApi('post', 'member/register'),

  // 查账户信息
  getMemberBaseInfo: UserApi('get', 'member/getMemberBaseInfo'),

  // 更新账号信息
  updateMemberBaseInfo: UserApi('post', 'member/updateMemberBaseInfo'),

  // 查询登录设置
  getUserLoginSet: UserApi('get', 'member/getUserLoginSet'),

  // 登录设置修改
  setUserLogin: UserApi('post', 'member/setUserLogin'),

  // 修改管理员
  changeAdminMember: UserApi('post', 'member/changeAdminMember'),

  // 角色列表下拉框
  getRoleList: UserApi('get', 'member/getRoleGroupNameList'),

  // 角色列表页
  getRoleGroupNameList: UserApi('post', 'member/getRoleGroupList'),

  // 员工账号列表
  getMemberAccountList: UserApi('post', 'member/getMemberAccountList'),

  // 获取员工名列表
  getMemberNameList: UserApi('get', 'member/getMemberNameList'),

  // 权限列表
  // getPermissionList: UserApi('get', 'member/getPermissionList'),

  // 获取角色组列表
  getPermissionList: UserApi('get', 'member/getPermissionsByRoleGroup'),

  // 创建员工账号
  createMember: UserApi('post', 'member/createMember'),

  // 修改时获取用户信息
  getMemberByIdInfo: UserApi('get', 'member/getMemberById'),

  // 删除员工账号
  deleteMember: UserApi('post', 'member/deleteMember'),

  // 修改员工账号
  modifyMember: UserApi('post', 'member/modifyMember'),

  // 审核员工账号
  auditMember: UserApi('post', 'member/audit'),

  // 开启免审核
  openAuditFree: UserApi('post', 'member/openAuditFree'),

  // 创建角色
  addRole: UserApi('post', 'member/addRole'),

  // 修改角色
  modifyRole: UserApi('post', 'member/modifyRole'),

  // 修改时获取角色信息
  getRoleGroupByIdInfo: UserApi('get', 'member/getRoleGroupById'),

  // 删除角色
  deleteRoleGroup: UserApi('post', 'member/deleteRoleGroup'),

  // 发送验证码
  sendVerCodeMessage: UserApi('post', 'vercode/sendVerCodeMessage'),

  // 标签列表
  getLabelList: UserApi('get', 'label/getLabelList'),

  // 新增/修改标签分组
  saveLabelType: UserApi('post', 'label/saveLabelType'),

  // 新增/修改标签
  saveLabel: UserApi('post', 'label/saveLabel'),

  // 删除标签
  deleteLabel: UserApi('get', 'label/deleteLabel'),

  // 删除标签分组
  deleteLabelType: UserApi('get', 'label/deleteLabelType'),

  // 交易管理订单列表
  getListMemberOrder: UserApi('post', 'order/listMemberOrder'),

  // 发货
  delivery: UserApi('post', 'order/delivery'),

  // 订单设置获取地址
  getCityList: UserApi('get', 'geo/listChildren'),

  // 交易设置设置运费
  setTradSetting: UserApi('post', 'sysSetting/updateTradeSetting'),

  // 获取运费设置
  getTradSetting: UserApi('get', 'sysSetting/getTradSetting')
};
