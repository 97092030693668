/**
 *  Date    : 2021/12/09
 *  Author  : weiLin
 *  Declare : 默认菜单
 */
import Utils from '@/utils/utils';

// 默认菜单
export const PRIVATE_DOMAIN_PERMISSIONS = [
  {
    iconType: 'home',
    id: 1,
    invalid: false,
    parentId: 0,
    name: '首页概览',
    path: '/industry/home',
    typeCode: 'HOME'
  },
  {
    iconType: 'book',
    id: 2,
    invalid: false,
    parentId: 0,
    name: '现代纸书',
    path: '/industry/materials',
    typeCode: 'BOOK'
  },
  {
    id: 3,
    invalid: false,
    parentId: 2,
    path: '/industry/materials/:sceneId/scene',
    name: '印码物料',
    typeCode: 'BOOK_GOODS'
  },
  {
    id: 6,
    invalid: false,
    parentId: 2,
    path: '/industry/materials/:type/qrcode',
    name: '二维码',
    typeCode: 'BOOK_QR'
  },
  {
    iconType: 'analysis',
    id: 10,
    invalid: false,
    parentId: 0,
    name: '数据中心',
    path: '/industry/analysis',
    typeCode: 'ANALYSIS'
  },
  {
    id: 11,
    invalid: false,
    parentId: 10,
    path: '/industry/analysis/general',
    name: '数据概览'
  },
  {
    id: 12,
    invalid: false,
    parentId: 10,
    path: '/industry/analysis/tourist',
    name: '游客统计'
  },
  {
    id: 13,
    invalid: false,
    parentId: 10,
    path: '/industry/analysis/newexhibit',
    name: '现代展品统计'
  },
  {
    id: 14,
    invalid: false,
    parentId: 10,
    path: '/industry/analysis/appcensus',
    name: '应用统计'
  },
  {
    id: 15,
    invalid: false,
    parentId: 10,
    path: '/industry/analysis/shopcensus',
    name: '商城统计'
  },
  {
    iconType: 'ma',
    id: 16,
    invalid: false,
    parentId: 0,
    name: '读者运营',
    path: '/industry/reader',
    typeCode: 'READER'
  },
  {
    id: 17,
    invalid: false,
    parentId: 16,
    path: '/industry/reader/list',
    name: '读者管理'
  },
  {
    id: 18,
    invalid: false,
    parentId: 16,
    path: '/industry/reader/message/list',
    name: '消息互动'
  },
  {
    iconType: 'ma',
    id: 19,
    invalid: false,
    parentId: 0,
    name: '应用服务',
    path: '/industry/app',
    typeCode: 'APP'
  },
  {
    id: 20,
    invalid: false,
    parentId: 19,
    path: '/industry/app/store',
    name: '应用中心'
  },
  {
    id: 21,
    invalid: false,
    parentId: 19,
    path: '/industry/app/list',
    name: '我的应用'
  },
  {
    id: 22,
    invalid: false,
    parentId: 21,
    path: '/industry/app/list/apply',
    name: '全部应用'
  },
  {
    id: 23,
    invalid: false,
    parentId: 21,
    path: '/industry/app/list/check',
    name: '应用审核'
  },
  {
    id: 24,
    invalid: false,
    parentId: 19,
    path: '/industry/app/library',
    name: '我的素材'
  },
  {
    iconType: 'ma',
    id: 25,
    invalid: true,
    parentId: 0,
    name: '账号设置',
    path: '/industry/setting',
    typeCode: 'SETTING'
  },
  {
    id: 26,
    invalid: false,
    parentId: 25,
    path: '/industry/setting/basic',
    name: '我的账号'
  },
  {
    id: 27,
    invalid: false,
    parentId: 25,
    path: '/industry/setting/config',
    name: '机构设置'
  },
  {
    id: 28,
    invalid: false,
    parentId: 25,
    path: '/industry/setting/staff/list',
    name: '员工账号'
  },
  {
    id: 29,
    invalid: false,
    parentId: 25,
    path: '/industry/setting/role/list',
    name: '角色管理'
  },
  {
    id: 30,
    invalid: false,
    parentId: 25,
    path: '/industry/setting/service',
    name: '服务号设置'
  },
  {
    id: 31,
    invalid: false,
    parentId: 25,
    path: '/industry/setting/label',
    name: '标签配置'
  },
  {
    id: 32,
    invalid: false,
    parentId: 25,
    path: '/industry/setting/log',
    name: '操作日志'
  }
];

// 格式化配置路由
export default function userPermission(permission) {
  let [...routerPath] = permission || PRIVATE_DOMAIN_PERMISSIONS;
  return Utils.unFlatMap(routerPath);
}
