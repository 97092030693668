import { Upload, InputNumber, DatePicker, message } from 'antd';

// 初始化加载
message.config({
  top: '20vh',
  duration: 2
});

Upload.__hasLoad = true;
DatePicker.__hasLoad = true;
InputNumber.__hasLoad = true;
