import Utils from '@/utils/utils';

export const PAGES = {
  HOME: '/industry/home',
  LOGIN: '/login',
  MATERIALS_LIST: '/industry/materials/:id/scene',
  MATERIALS_ADD: '/industry/materials/scene/:id/add', // 物料
  SCENE_MATCH: '/industry/materials/scene/:id/match',
  SCENE_ANALYSIS: '/industry/materials/scene/:id/analysis',
  QR_CODE_LIST: '/industry/materials/:id/qrcode',
  QR_CODE_ADD: '/industry/materials/qrcode/:type/add',
  QR_CODE_MATCH: '/industry/materials/qrcode/:id/match',
  QR_CODE_RECOVERY: '/industry/materials/qrcode/recovery',
  MATERIALS_RECOVERY: '/industry/materials/scene/recovery',
  APP_STORE: '/industry/app/store',
  APP_LIST: '/industry/app/list/apply',
  APP_INFO: '/industry/app/list/:id/info',
  APP_CREATE: '/industry/app/:typeCode/add',
  SETTING: '/industry/setting/basic', // 账户设置
  SETTING_ADD: '/industry/setting/:type/add',
  READER_LIST: '/industry/reader/list',
  READER_MESSAGE: '/industry/reader/message',
  READER_INFO: '/industry/reader/:id/info'
};

// 获取带params路由hooks
export function pageHooks(type, params, query) {
  let path = PAGES[type];
  // 是否需要参数
  const hasParams = (value) => value.includes(':');
  // 判断一下提高一点点性能
  if(typeof params === 'object') {
    return console.error('params must be string!');
  }
  if(hasParams(path) && params) {
    path = path.split('/').reduce((pre, next) => (hasParams(next) ? pre.concat(params) : pre.concat(next)), []).join('/');
  }
  return `${path}${Utils.formatQuery2QueryStr(query)}`;
}

export default PAGES;

export function getPageUrl(page, params) {
  let url = page;
  Object.keys(params)
    .forEach(key => {
      if(url.indexOf(`:${key}`) > -1) {
        url = url.replace(`:${key}`, params[key]);
        delete params[key];
      }
    });
  return url + Utils.formatQuery2QueryStr(params);
}

