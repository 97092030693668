import axiosServices from './request';

const MaterialApi = axiosServices('/party');

export default {
  // 根据场景id获取场景字段配置
  materialConfig: MaterialApi('get', 'party/getSceneOptionByCaseSceneId'),

  // 根据场景id获取场景字段配置
  getSceneListByIndustryCaseId: MaterialApi('get', 'party/getSceneListByIndustryCaseId'),

  //创建实物
  addMaterial: MaterialApi('post', 'model/insert'),

  //更新实物
  updateMaterial: MaterialApi('post', 'model/update'),

  //删除实物
  deleteMaterial: MaterialApi('get', 'model/deleteById'),

  // 获取印码实物列表
  getMaterialList: MaterialApi('post', 'model/getList'),

  // 获取实物详情
  getMaterialInfo: MaterialApi('get', 'model/getById'),

  // 回收站列表
  materialRecycleList: MaterialApi('post', 'model/recycleBinList'),

  // 从回收站还原
  materialRecycleModel: MaterialApi('get', 'model/recycleModel'),

  // 数据概览 /material/getMaterialStatisById?materialId=1
  getModelStatisById: MaterialApi('get', 'data/getModelStatisById')

};
