/**
 *  Date    : 2021/11/18
 *  Author  : WeiLin
 *  Declare : 通用常量
 */

// 环境标识
export const ENV = {
  DEV: 'DEV',
  TEST: 'TEST',
  UAT: 'UAT',
  PRO: 'PRO'
};

export const TOKEN = 'TOKEN';

export const EMPTY_PAGE_BEAN = {
  recordList: []
};

//表图数据初始值
export const EMPTY_CHART_DATA = {
  values: [[]],
  axis: [],
  axisSort: []
};

export const NOOP = () => {};   // 无操作，常用于props缺省值
export const JUST_RETURN = _ => _;    // 直接返回
export const RETURN_EMPTY_OBJECT = () => ({});    // 返回空对象，常用于connect传递空的mapStateToProps
export const PREVENT_DEFAULT = event => event.preventDefault();
export const STOP_PROPAGATION = event => event.stopPropagation();

export const FILE_TYPE = {
  AUDIO: 'AUDIO',
  VIDEO: 'VIDEO',
  IMAGE: 'IMAGE',
  EXCEL: 'EXCEL',
  PDF: 'PDF',
  WORD: 'WORD',
  PPT: 'PPT',
  ZIP: 'ZIP',
  TXT: 'TXT'
};

export const FILE_EXT = {
  [FILE_TYPE.AUDIO]: ['mp3', 'wav', 'aac', 'wma', 'ape', 'amr'],
  [FILE_TYPE.VIDEO]: ['mp4', 'mkv', 'mpg', 'avi', 'asx', 'mov', 'flv', 'rm', 'wmv', 'rmvb'],
  [FILE_TYPE.IMAGE]: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'swf', 'tiff', 'psd', 'svg', 'webp'],
  [FILE_TYPE.EXCEL]: ['csv', 'xls', 'xlsx', 'xlsm', 'xltm', 'xlam'],
  [FILE_TYPE.PDF]: ['pdf'],
  [FILE_TYPE.WORD]: ['doc', 'docx'],
  [FILE_TYPE.PPT]: ['ppt', 'pptx'],
  [FILE_TYPE.ZIP]: ['zip', 'rar'],
  [FILE_TYPE.TXT]: ['txt']
};

export const FILE_FORMAT = (needConvert = true) => ({
  PDF: {
    accept: 'application/pdf',
    name: 'PDF',
    tip: ''
  },
  AUDIO: {
    accept: needConvert
      ? 'audio/mp3, audio/wav, audio/aac, audio/x-ms-wma, audio/x-monkeys-audio, audio/amr'
      : 'audio/mp3',
    name: '音频',
    tip: needConvert
      ? '上传的音频将自动转换为mp3格式。'
      : '音频文件支持MP3格式。'
  },
  VIDEO: {
    accept: needConvert
      ? 'video/mp4, video/x-matroska, video/mpeg, video/x-msvideo, video/quicktime, video/x-flv, application/vnd.rn-realmedia, video/x-ms-wmv, application/octet-stream'
      : 'video/mp4, video/x-matroska',
    name: '视频',
    tip: needConvert
      ? '上传的视频将自动转换为mp4格式。'
      : '视频文件支持MP4格式。'
  },
  ALBUM: {
    accept: 'image/png, image/gif, image/jpeg',
    name: '图片',
    tip: ''
  },
  LYRIC: {
    accept: '',
    name: '歌词',
    tip: '歌词文件支持LRC格式。'
  },
  EXCEL: {
    accept:
      'text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    name: 'EXCEL表格',
    tip: '文件格式支持csv、xls、xlsx、xlsm、xltm、xlam格式'
  },
  ZIP: {
    accept: 'application/zip, application/x-rar-compressed',
    name: '压缩文件',
    tip: '压缩文件支持ZIP、RAR格式。'
  },
  'AUDIO-TXT': {
    accept: 'text/plain',
    name: '音频原文',
    tip: '音频原文文件支持TXT格式。'
  },
  WORD: {
    accept:
      'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    name: 'WORD文档',
    tip: '文件格式支持doc、docx格式'
  },
  PPT: {
    accept:
      'application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation',
    name: 'PPT文档',
    tip: '文件格式支持ppt、pptx格式'
  },
  EBOOK: {
    accept: 'epub',
    name: '电子书',
    tip: '文件格式支持epub格式'
  }
});

export const DEFAULT_AVATAR = {
  ERROR: '',
  USER: 'https://r.5rs.me/image/920b35ee-4e66-4486-8963-6548279381e1/0cd6b9c35a982fdcd008bbba386c568f.png'
};
export const DEFAULT_EMPTY = {
  DATA: 'https://oss.5rs.me/oss/uploadfe/png/9f9e9912d84a842f66ed1620352a03f4.png',     //暂无数据
  USER: 'https://oss.5rs.me/oss/uploadfe/png/82c59a58989a4ca8e92b45c5873b98a3.png',      //暂无读者
  UPDATE: 'https://oss.5rs.me/oss/uploadfe/png/69f373e609820665ae8977a2476b1abb.png',      //正在建设
  SEARCH: 'https://oss.5rs.me/oss/uploadfe/png/14d19ab7f4a8fa09ee66511d45cfa214.png',     //搜索结果为空
  QR_CODE: 'https://oss.5rs.me/oss/uploadfe/png/0ab49a4c75d84045eaea235cc7c4d62a.png',    //暂无二维码
  WIFI: 'https://oss.5rs.me/oss/uploadfe/png/a303cef5a1df54b0f80d6cc492bb7d91.png',       //暂无网络
  MESSAGE: 'https://oss.5rs.me/oss/uploadfe/png/64dac03a89ee654a3a0ce690901f0d3b.png'      //暂无消息
};

export const MOBILE_REG = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;  //手机号正则校验

export const PARTY_NAME_REG = /[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]/ig;   //校验特殊字符

export const DEFAULT_IMG = 'https://file.5rs.me/oss/uploadfe/png/bae2a13d9f5ca1dc0656c739f355548c.png';

export const DEFAULT_BIG_IMG = 'https://file.5rs.me/oss/uploadfe/png/086a2c79bdc5a21ca67e8286ba9425c2.png';

export const DEFAULT_BIG_IMG1 = 'https://file.5rs.me/oss/uploadfe/png/2d4f68ee7ba753f8c1eea54c208c4486.png';

export const TIPS_IMG_LIST = {
  'AUDIO': 'https://file.5rs.me/oss/uploadfe/png/d56e6510578601258a5aeaf154202074.png',
  'VIDEO': 'https://file.5rs.me/oss/uploadfe/png/75a8471ca31a2a4ed63942e80455108d.png'
};

// 二维码类型
export const QR_CODE_TYPE = {
  wechat: '公众号码',
  own: '自有码'
};
