/**
 *  Date    : 2021/11/22
 *  Author  : WeiLin
 *  Declare : analysis
 */
import Immutable from 'seamless-immutable';
import analysisApi from '@/api/analysis';
import { LabelModal } from '@/models/analysis/model';
// import { EMPTY_PAGE_BEAN } from '@/constants/common';

export default {
  namespace: 'analysis',
  state: Immutable({
    labelList: []
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data })
  },
  actions: {
    getLabelList(payload) {
      return {
        type: 'analysis/update/labelList',
        field: 'labelList',
        promise: analysisApi.getLabelList(payload),
        model: LabelModal
      };
    }
  }
};
