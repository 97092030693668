import React from 'react';
import Loadable from 'react-loadable';

import { createRouter } from '@/utils/router';
import Loading from '@/components/Loading';

import App from './App';
import PAGES from '@/constants/page';

export default createRouter({
  getComponent: () => App,
  onEnter: (nextState, history) => {
    const { location } = nextState;
    const locationArr = location.pathname.split('/').filter(v => v);
    if(locationArr.length === 1 && !locationArr.includes('thirdAuthorize')) {
      history.push(location.pathname + PAGES.HOME + location.search);
    }
  },
  getChildRoutes: () => [
    {
      path: '/:code?(/)?login',
      component: Loadable({
        loader: () => import('./login'),
        loading: () => <Loading />
      })
    },
    {
      path: '/:code?(/)?industry',
      component: Loadable({
        loader: () => import('./pages'),
        loading: () => <Loading />
      })
    },
    {
      path: '/thirdAuthorize',
      component: Loadable({
        loader: () => import('./authorize'),
        loading: () => <Loading />
      })
    },
    {
      path: '*',
      component: Loadable({
        loader: () => import('./404'),
        loading: () => <Loading />
      })
    }
  ]
});
