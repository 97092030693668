import axiosServices from './request';

const QRCodeApi = axiosServices('/party');

export default {
  // 二维码列表
  getQRCodeList: QRCodeApi('post', 'qrcode/getQrcodeList'),

  // 创建二维码
  createQRCode: QRCodeApi('post', 'qrcode/createQrcode'),

  // 更新二维码
  updateQrcode: QRCodeApi('post', 'qrcode/updateQrcode'),

  // 删除二维码
  deleteQrcode: QRCodeApi('get', 'qrcode/deleteBySceneId'),

  // 回收站中二维码列表
  recycleQrcode: QRCodeApi('post', 'qrcode/listPageRecycleBinQrcode'),

  // 回收站删除二维码
  deleteRecycleBin: QRCodeApi('get', 'qrcode/deleteRecycleBinBySceneId'),

  // 禁用二维码
  setSceneIsDisable: QRCodeApi('post', 'qrcode/setSceneIsDisable'),

  // 回收站恢复二维码
  recoverQrcode: QRCodeApi('get', 'qrcode/recoverRecycleBinQrcode'),

  // 二维码导出
  exportQRCode: QRCodeApi('post', 'qrcode/exportQrcode'),

  // 二维码基本信息
  getQRCodeInfo: QRCodeApi('get', 'qrcode/getQrcodeById'),

  // 物料下二维码目录及二维码列表
  getModelQrCatalogDetail: QRCodeApi('get', 'qrcode/getModelQrCatalogDetail'),

  // 开启关闭展品信息
  updateShowModel: QRCodeApi('get', 'qrcode/updateShowModel'),

  // 查询自有码配置的素材
  getOwnSceneConfig: QRCodeApi('get', 'qrcode/getQrcodePageBySceneId'),

  // 查询公众号二维码配置的素材
  getWeChatSceneConfig: QRCodeApi('get', 'qrcode/getQrcodeNewsBySceneId'),

  // 自有码配置素材
  setQrCodeRays: QRCodeApi('post', 'qrcode/setQrcodeResource'),

  // 公众号二维码配置素材
  setQrCodeWeCart: QRCodeApi('post', 'qrcode/setQrcodeNews'),

  // 数据统计-二维码应用统计-应用统计列表
  getQrcodeAppStatisList: QRCodeApi('post', 'data/listQrcodeAppStatis'),

  // 数据统计-二维码应用统计-二维码统计
  getQrcodeStatisById: QRCodeApi('get', 'data/getQrcodeStatisById'),

  // 数据统计-二维码应用统计-访问趋势
  getQrcodeDayStatisList: QRCodeApi('post', 'data/getQrcodeDayStatisList')

};
