/**
 *  Date    : 2021/11/22
 *  Author  : WeiLin
 *  Declare : qrcode
 */
import Immutable from 'seamless-immutable';
import { EMPTY_PAGE_BEAN } from '@/constants/common';
import appApi from '@/api/appApi';

export default {
  namespace: 'app',
  state: Immutable({
    appList: EMPTY_PAGE_BEAN,
    appTypeList: [],
    appCatalogList: [],
    appTypeMap: {}
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data }),
    updateAppTypeList: (state, { data }) => {
      const appCatalogMap = {};
      const appTypeMap = {};
      if(data && data.length) {
        data.forEach(item => {
          // 格式化分类数据
          if(appCatalogMap[item.typeSort]) {
            appCatalogMap[item.typeSort].push(item);
          } else {
            appCatalogMap[item.typeSort] = [item];
          }

          // 格式化MAP type
          appTypeMap[item.typeCode] = item;
        });
      }
      return state.merge({
        appTypeList: data,
        appCatalogList: Object.keys(appCatalogMap).map(key => {
          return {
            typeSort: key,
            typeList: appCatalogMap[key],
            typeSortName: appCatalogMap[key][0].typeSortName
          };
        }),
        appTypeMap
      });
    }
  },
  actions: {
    getAppList(payload) {
      return {
        type: 'app/update/appList',
        field: 'appList',
        promise: appApi.getAppList(payload)
      };
    },
    getAppTypeList(payload) {
      return {
        type: 'app/updateAppTypeList',
        field: 'appTypeList',
        promise: appApi.getAppTypeList(payload)
      };
    }
  }
};
