/**
 * Date    : 2021/11/16
 * Author  : weiLin
 * Declare : index
 */

import React from 'react';
import { Spin } from 'antd';

import './index.scss';

export default function Loading(props) {

  const { computedMatch, location, ...restProps } = props;

  return (
    <div className="Loading">
      <Spin {...restProps} />
    </div>
  );
}
