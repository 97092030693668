/**
 *  Date    : 2021/11/22
 *  Author  : WeiLin
 *  Declare : materials
 */
import Immutable from 'seamless-immutable';

import materialApi from '@/api/materialApi';
// import Utils from '@/utils/utils';
import { EMPTY_PAGE_BEAN } from '@/constants/common';

export default {
  namespace: 'materials',
  state: Immutable({
    materialList: EMPTY_PAGE_BEAN
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data })
  },
  actions: {
    getMaterialList(payload) {
      return {
        type: 'materials/update/materialList',
        field: 'materials',
        promise: materialApi.getMaterialList(payload)
        // model: ProjectModel.ProjectStateDistribution
      };
    }
  }
};
