import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import zhCN from 'antd/lib/locale/zh_CN';
import { useSelector } from '@/utils/redux';

import './App.scss';

function App(props) {

  const { industryCaseInfo } = useSelector(state => state.user);

  const changeColor =  (industryCaseInfo) => {

    const { primaryColor, secondaryColor, contrastColor } = industryCaseInfo;

    // 修改通用颜色
    document.body.style.setProperty('--primary-color', primaryColor);
    document.body.style.setProperty('--secondary-color', secondaryColor);
    document.body.style.setProperty('--contrast-color', contrastColor);

    // 修改组件颜色
    ConfigProvider.config({
      theme: {
        primaryColor: primaryColor
      }
    });
  };

  useEffect(() => {
    changeColor(industryCaseInfo);
  }, [industryCaseInfo]);

  return (
    <ConfigProvider locale={zhCN}>
      <Switch>
        {props.children}
      </Switch>
    </ConfigProvider>

  );
}

export default App;
