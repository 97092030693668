import axiosServices from './request';

const CommonApi = axiosServices('/common');
const Party = axiosServices('/party');

export default {
  // 获取上传参数
  getUploadParam: CommonApi('get', 'obs/getCreatePostSignature'),

  addResource: Party('post', 'resource/addResource'),

  checkFile: Party('get', 'resource/checkFile'),

  //新增素材(视频)
  addVideo: Party('post', 'resource/addVideo'),
  //新增素材(音频)
  addAudio: Party('post', 'resource/addAudio')
};
