
import user from './user';
import projectSetting from './project-setting';
import common from './common';
import material from './materials';
import qrcode from './qrcode';
import app from './app';
import analysis from './analysis';

export default [
  user,
  common,
  projectSetting,
  material,
  qrcode,
  app,
  analysis
];
