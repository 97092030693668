/**
 *  Date    : 2019/11/7
 *  Author  : weiLin
 *  Declare : user
 */
import Immutable from 'seamless-immutable';

import userApi from '@/api/userapi';
import Utils from '@/utils/utils';
import materialApi from '@/api/materialApi';

// import { AccountModal } from './model';
import userPermission from './menus';

export default {
  namespace: 'user',
  state: Immutable({
    userInfo: {
      loading: true,
      permissionMenus: [],
      flatPermissionMenus: Utils.flatMap([])
    },
    partyNameList: [],
    memberAccountList: {},
    roleList: [],
    labelList: [],
    memberBaseInfo: {},
    industryCaseInfo: {
      modelKey: '书刊',
      qrcodeKey: '二维码',
      readerKey: '读者'
    },
    sceneCase: [],
    industryAccount: [],
    institutionSetting: false
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data }),
    updateUserInfo: (state, { data, field }) => {
      // 取首个场景id
      const [sceneItem = {}] = JSON.parse(JSON.stringify(state.sceneCase));

      const { permissionVOList } = data;
      permissionVOList.sort((pre, next) => pre.seq - next.seq);

      // 更新动态 二维码 或 展品路由
      permissionVOList.forEach(item => {
        if(item.path === '/industry/materials/:type/scene') {
          item.path = `/industry/materials/${sceneItem.caseSceneId}/scene`;
        }
      });
      return state.merge({
        [field]: Object.assign({}, data, {
          sceneCaseId: sceneItem.caseSceneId, // 保存第一个场景id
          permissionMenus: userPermission(permissionVOList),
          flatPermissionMenus: Utils.flatMap(userPermission(permissionVOList)),
          loading: false
        })
      });
    }
  },
  actions: {
    // 获取用户信息
    getUserInfo() {
      return {
        type: 'user/updateUserInfo',
        field: 'userInfo',
        promise: userApi.getUserInfo()
          .then(data => {
            return data;
          })
      };
    },
    // 获取机构列表
    getPartyNameList(data) {
      return {
        type: 'user/update/updatePartyNameList',
        field: 'partyNameList',
        promise: userApi.getPartyNameList(data)
          .then(res => {
            return res;
          })
      };
    },

    // 获取员工账号列表
    getMemberAccountList(data) {
      return {
        type: 'user/update/updateMemberAccountList',
        field: 'memberAccountList',
        promise: userApi.getMemberAccountList(data)
          .then(res => {
            return res;
          })
      };
    },

    // 获得角色列表
    getRoleList(data) {
      return {
        type: 'user/update/updateRoleList',
        field: 'roleList',
        promise: userApi.getRoleList(data)
          .then(res => {
            return res;
          })
      };
    },

    //获得标签列表
    getLabelList(data) {
      return {
        type: 'user/update/updateLabelList',
        field: 'labelList',
        promise: userApi.getLabelList(data)
          .then(res => {
            return res;
          })
      };
    },

    //查账号信息
    getMemberBaseInfo() {
      return {
        type: 'user/update/updateMemberBaseInfo',
        field: 'memberBaseInfo',
        promise: userApi.getMemberBaseInfo()
          .then(res => {
            return res;
          })
      };
    },
    // 登录
    login(payload) {
      return {
        promise: userApi.login(payload)
      };
    },
    // 通过域名获取产品信息 getIndustryCase
    getIndustryCase(data) {
      return {
        type: 'user/update/updateIndustryCaseInfo',
        field: 'industryCaseInfo',
        promise: userApi.getIndustryCase(data)
          .then(item => {
            return Object.assign({}, item, {
              primaryColor: item.color1,
              secondaryColor: item.color2,
              contrastColor: item.color3,
              industryCaseId: item.industryCaseId,
              industryId: item.industryId,
              modelKey: item.modelTerm || '书刊',
              qrcodeKey: item.qrcodeTerm || '二维码',
              readerKey: item.readerTerm || '读者'
            });
          })
      };
    },
    // 通过域行业获取 场景
    getSceneCase(data) {
      return {
        type: 'user/update/updateSceneCase',
        field: 'sceneCase',
        promise: materialApi.getSceneListByIndustryCaseId(data)
          .then(item => {
            return item;
          })
      };
    },
    getAccountList(data) {
      return {
        type: 'user/update/industryAccount',
        field: 'industryAccount',
        promise: userApi.getAccountSettingList(data)
          .then(item => {
            return item;
          })
      };
    },
    setInstitutionSetting(data) {
      return {
        type: 'user/update/institutionSetting',
        field: 'institutionSetting',
        data
      };
    }
  }
};
