import Immutable from 'seamless-immutable';
import commonApi from '@/api/common';

export default {
  namespace: 'common',
  state: Immutable({
    dynamicList: [],
    breadCrumbs: [],
    context: {},
    uploadConfig: {}
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data }),
    addRoute: (state, { data }) => {
      let dynamicArr = [];
      if(Array.isArray(data)) {
        dynamicArr = data;
      }
      if(data.constructor === Object && 'path' in data) {
        if(!state.dynamicList.some(v => v.path === data.path)) {
          dynamicArr = [...state.dynamicList, data];
        }
      }
      return state.merge({
        dynamicList: dynamicArr
      });
    },
    removeRoute: (state, { data }) => {
      let dynamicArr = [];
      if(data.constructor === Object && 'path' in data) {
        dynamicArr = state.dynamicList.filter(v => v.path !== data.path);
      }
      return state.merge({
        dynamicList: dynamicArr
      });
    },
    addBreadCrumb: (state, { data }) => {
      return state.merge({
        breadCrumbs: [data]
      });
    },
    removeBreadCrumb: (state) => {
      return state.merge({
        breadCrumbs: []
      });
    }
  },
  actions: {
    addRoute: function(router) {
      return {
        type: 'common/addRoute',
        data: router
      };
    },
    removeRoute: function(router) {
      return {
        type: 'common/removeRoute',
        data: router
      };
    },
    addBreadCrumb: function(breadcrumb) {
      return {
        type: 'common/addBreadCrumb',
        data: breadcrumb
      };
    },
    removeBreadCrumb: function(breadcrumb) {
      return {
        type: 'common/removeBreadCrumb',
        data: breadcrumb
      };
    },
    setSearchOperation(key, searchOptions) {
      return {
        type: `common/update/${key}`,
        field: key,
        data: searchOptions
      };
    },
    resetSearchOperations(key) {
      return {
        type: `common/update/${key}`,
        field: key,
        data: {}
      };
    },
    setContext: function(key, context) {
      return {
        type: `common/update/${key}`,
        field: key,
        data: context
      };
    },
    setUploadConfig(data) {
      return {
        type: 'common/update/updateUploadConfig',
        field: 'uploadConfig',
        promise: commonApi.getUploadParam({
          bizCode: 'industry'
        })
          .then(res => {
            return res;
          })
      };
    }
  }
};

