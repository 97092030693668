
export const isDevelopment = process.env.NODE_ENV !== 'production';

export const BASE_API_ROOT = window.location.origin;

// default domain
const domain = 'https://industry-pcloud.pdf-rays.com';
const appDomain = 'https://industry-app.pdf-rays.com';

// 系统角色
const systemCode = 'industry-web';

// 系统 ID
const systemId = '1';

const config = {
  isDevelopment,
  systemCode,
  industryCaseId: systemId,
  domain: isDevelopment ? domain : BASE_API_ROOT,
  appDomain: appDomain,
  systemCodeText: '业务端系统',
  COOKIE: `${systemCode}_token`,
  PRODUCTION_COOKIE: `${systemCode}_production_id`,
  iconfontURL: '//at.alicdn.com/t/font_1542801_kdyfn2gn11i.js'     // 字体图标库链接
};

export default config;
