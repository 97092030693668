/**
 *  Date    : 2021/11/22
 *  Author  : WeiLin
 *  Declare : qrcode
 */
import Immutable from 'seamless-immutable';
import qrcodeApi from '@/api/qrcodeApi';
import { EMPTY_PAGE_BEAN } from '@/constants/common';

// import qrcodeApi from '@/api/qrcodeApi';
// import Utils from '@/utils/utils';

export default {
  namespace: 'qrcode',
  state: Immutable({
    codeList: EMPTY_PAGE_BEAN
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data })
  },
  actions: {
    getQRCodeList(payload) {
      return {
        type: 'qrcode/update/codeList',
        field: 'qrcode',
        promise: qrcodeApi.getQRCodeList(payload)
        // model: ProjectModel.ProjectStateDistribution
      };
    }
  }
};
