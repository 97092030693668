import axiosServices from '@/api/request';

const AnalysisApi = axiosServices('/party');

export default {
  // 标签列表
  getLabelList: AnalysisApi('get', 'label/getLabelList'),

  // 新增/修改标签分组
  saveLabelType: AnalysisApi('post', 'label/saveLabelType'),

  // 新增/修改标签
  saveLabel: AnalysisApi('post', 'label/saveLabel'),

  // 删除标签分组
  deleteLabel: AnalysisApi('get', 'label/deleteLabel'),

  // 删除标签
  deleteLabelType: AnalysisApi('get', 'label/deleteLabelType')
};
