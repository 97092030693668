import axiosServices from './request';

const AppApi = axiosServices('/party');

export default {
  // 获取应用类型
  getAppTypeList: AppApi('get', 'app/getAppTypeList'),
  // 获取应用列表
  getAppList: AppApi('post', 'app/getAppList'),

  // 动态创建
  createAppByType: (params) => {
    return AppApi('post', `${params.typeCode.toLocaleLowerCase()}App/createApp`)(params);
  },

  // 动态更新
  updateAppByType: (params) => {
    return AppApi('post', `${params.typeCode.toLocaleLowerCase()}App/updateApp`)(params);
  },

  // 删除更新
  deleteAppByType: (params) => {
    return AppApi('get', `app/deleteApp`)(params);
  },

  // 删除目录中的某个素材 /app/deleteAppResource?appResourceId=55
  deleteAppResource: (params) => {
    return AppApi('get', `catalog/deleteAppResource`)(params);
  },

  // 删除目录
  deleteCatalog: (params) => {
    return AppApi('get', `catalog/deleteCatalogAndResource`)(params);
  },

  //动态获取
  getAppByType: (params) => {
    return AppApi('get', `${params.typeCode.toLocaleLowerCase()}App/getAppById`)(params);
  },
  // 审核应用
  auditApp: AppApi('post', 'app/auditApp'),

  // 提交审核
  submitAudit: AppApi('get', 'app/submitAudit'),

  // 获取用户报名列表
  getVoteOptionList: AppApi('get', 'voteApp/getVoteOptionList'),
  //审核
  auditVote: AppApi('post', 'voteApp/audit')

};
